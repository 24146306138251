import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Card, CardHeader, CardContent, Typography, CardActions, Button } from '@mui/material';

const getLogoutURL = "https://smtrade.online/api/users/signout";
const getJWTverify = "https://smtrade.online/api/users/verifyJWT";

const Dashboard = () => {
  const [token, setToken] = useState(Cookies.get('auth-token'));
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Dashboard';
    const verifyJWT = async () => {
      if (!token) {
        navigate('/login');
        return;
      }
      const config = { 
        headers: { 
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
        }
      };
      try {
        const response = await axios.get(getJWTverify, config);
        if (!response.data) {
          navigate('/login');
        }
      } catch (err) {
        console.error("Error during JWT verification: ", err);
        navigate('/login');
      }
    };
    verifyJWT();
  }, [navigate, token]);

  const signout = async () => {
    try {
      console.log("singout");
      // await axios.get(getLogoutURL, {
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   }
      // });
      Cookies.remove('auth-token', { domain: '.smtrade.online' });
      setToken(null);
      console.log(Cookies.get('auth-token'));
      console.log(token);
      navigate("/login");
    } catch (error) {
      console.error("Logout failed: ", error);
    }
  };

  return (
    <div className='box' style={{display:'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center', transform: 'translate(0%, 60%)', alignContent:'center'}}>
      <Card>
        <CardHeader title="CT API"/>
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            DESCRIPTION FOR CT API
          </Typography>
        </CardContent>
        <CardActions>
          <a href="https://ct.smtrade.online/dashboard" style={{textDecoration: 'none'}}>
            <Button size="medium">Visit</Button>
          </a>
        </CardActions>
      </Card>
      <Button size="large" onClick={signout} style={{marginTop: '20px'}}>Sign Out</Button>
    </div>
  );
}

export default Dashboard;
