import React from 'react'
import { BrowserRouter as Router, Route, Link, Routes, createBrowserRouter, RouterProvider, Switch } from "react-router-dom";
import { Login } from './pages/Login';
import Dashboard from './pages/DashboardSM';
const ReactRoutes = () => {
  return (
    <div>
        <Router>
            <Routes>
                <Route path="/dashboard" element={<Dashboard/>}>
                </Route>
                <Route path="/login" element={<Login/>}>
                </Route>
            </Routes>
        </Router>
  </div>
  )
}

export default ReactRoutes