'use client';
import * as React from 'react';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Grid from '@mui/material/Unstable_Grid2';
import axios from 'axios'
import Cookies from 'js-cookie';
import { Navigate, useNavigate } from "react-router-dom";

const getLoginURL = "https://smtrade.online/api/users/signin"

export function Login(){
  let navigate = useNavigate();
    const [serverResponse, setServerResponse] = useState('')
    let [customAuth, setCustomAuth] = useState('')
    
    const [userData, setUserData] = useState({
        username: '',
        password: ''
    })
    
    async function handleSubmit(event){
      event.preventDefault()
      
      if (userData.username!=='' && userData.password!==''){
        await axios.post(getLoginURL, userData)
        .then((response) => {
          Cookies.set('auth-token', response.data.token, { domain: '.smtrade.online' });
          // Cookies.set('auth-token', response.data.token)
          setServerResponse(response.data.token)
        })
        .catch((err) => {
          alert(err.response.data.message)
        });
      }
    }
    useEffect(()=>{
      if (Cookies.get('auth-token')!==undefined && Cookies.get('auth-token')!==null){
        navigate("/dashboard")
      }
    },[serverResponse])
      
    
    return(
        <div style={{top:'50%'}}>
            <div>
            <form style={{display:'flex',justifyContent:'center', flexDirection: 'row', transform: 'translate(0%, 50%)'}}>
            <Card style={{display:'flex',justifyContent:'center', flexDirection: 'column', width:'max-content'}}>
              <CardHeader title="Login" style={{ padding: '13px', paddingTop: '20px', textAlign:'center'}}/>
              <Divider />
              <CardContent style={{display:'flex',justifyContent:'center'}}>
                <Grid container spacing={3} style={{display:'flex',justifyContent:'center'}}>
                  <Grid md={10} xs={12} style={{display:'flex',justifyContent:'center'}}>
                    <FormControl>
                      <InputLabel>Username</InputLabel>
                      <OutlinedInput style={{ height: '40px', position: 'relative', top: '6px'}} label="Username" type="text" name="username" onChange={(e) => {setUserData({...userData,[e.target.name]:e.target.value})}}  value={userData.username} />
                    </FormControl>
                  </Grid>
                  <Grid md={10} xs={12} style={{display:'flex',justifyContent:'center'}}>
                    <FormControl>
                      <InputLabel>Password</InputLabel>
                      <OutlinedInput style={{ height: '40px', position: 'relative', top: '6px' }} label="Password" type="password" name="password" onChange={(e) => {setUserData({...userData,[e.target.name]:e.target.value})}} value={userData.password} />
                    </FormControl>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardActions sx={{ display:'flex',justifyContent:'center'}} style={{padding: '5px'}}>
                <Button type="submit" variant="contained" onClick={(e)=>{handleSubmit(e)}}>Submit</Button>
              </CardActions>
            </Card>
          </form>
          </div>
            
        </div>
    )
}